import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { webApiUrls } from "@core/constants/url.constant";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { Observable, catchError, map, of } from "rxjs";
import { AuthGuard } from "./auth.guard";

interface ISamlParams {
    SAMLRequest: string,
    RelayState: string,
}

interface ISaml {
    spUrl: string,
    samlRespond: string,
    relayState: string,
}

// const samlMocks = {
//     "spUrl": "https://monitoringstage.edge.domain/simplesaml/module.php/saml/sp/saml2-acs.php/default-sp",
//     "samlRespond": "PHNhbWxwOlJlc3BvbnNlIHhtbG5zOnNhbWw9InVybjpvYXNpczpuYW1lczp0YzpTQU1MOjIuMDphc3NlcnRpb24iIElEP",
//     "relayState": "https://monitoringstage.edge.domain/em7/index.em7?exec=availability&did=355223 HTTP/1.1"
// };

// Here we are checking access and authorize to ScienceLogic third party service.
// This functionality can be tested only on Production env.
// This happend because when we openning link we have status 302
// and it redirect us to https://support.databank.com/saml with queryParams
// for authorisation.

export const SamlGuard: CanActivateFn = (route) => {
    const router = inject(Router);
    const queryParams = route.queryParams as ISamlParams;
    const queryPath = route.routeConfig.path as string;

    if (!queryParams?.SAMLRequest && !queryParams?.RelayState) {
        return redirectToLogin(router)
    }
    if (!AuthGuard()) {
        saveLastUrl();
    }

    return getSaml(queryParams, queryPath).pipe(
        catchError(() => of(null)),
        map((params) => {
            if (params) {
                redirectAndAuthorize(params);
                return true
            }
            return redirectToLogin(router)
        })
    );
}

function saveLastUrl() {
    const window = inject(Window);
    let url = window.location.href;
    const settingStorageService = inject(SettingStorageService);
    if (url) {
        let urlObj = new URL(url);
        let path = urlObj.pathname;
        let searchParams = urlObj.searchParams;
        let queryParams = {};

        //getting the searchParams
        for(let pair of searchParams.entries()) {
            if (pair !== null && pair.length >= 2) {
                queryParams[pair[0]] = pair[1];
            }
        }

        settingStorageService.setRedirectUrl({
            path: path,
            queryParams: queryParams || null,
        });
    }
}

function redirectToLogin(router: Router): UrlTree {
    return router.parseUrl("/login")
}

function getSaml(params: ISamlParams, queryPath: string): Observable<ISaml> {
    const http = inject(HttpClient)
    let path = `${webApiUrls.auth}/saml/scl`;
    if (queryPath === "saml/panorama")
        path = `${webApiUrls.auth}/${queryPath}`;

    return http.get<ISaml>(path, { params: { ...params } })
}

function redirectAndAuthorize(params: ISaml) {
    // Using this form for redirecting to a third party Web Page
    // and adding query params.
    // TODO: Find another way how to do that.
    let form = document.createElement("FORM");
    form.setAttribute("action", params.spUrl);
    form.setAttribute("method", "post");
    form.setAttribute("id", "frmSaml");
    form.setAttribute("name", "saml");

    let SAMLResponse = document.createElement("INPUT");
    SAMLResponse.setAttribute("type", "hidden");
    SAMLResponse.setAttribute("name", "SAMLResponse");
    SAMLResponse.setAttribute("value", params.samlRespond);

    let RelayState = document.createElement("INPUT");
    RelayState.setAttribute("type", "hidden");
    RelayState.setAttribute("name", "RelayState");
    RelayState.setAttribute("value", params.relayState);

    form.appendChild(SAMLResponse);
    form.appendChild(RelayState);
    document.body.appendChild(form);
    //@ts-ignore
    form.submit();
}
