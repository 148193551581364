import { Params } from "@angular/router";
import { PERMISSIONS, PERMISSIONS_ACTIONS } from "@core/constants/permission.constant";
import { UtilityService } from "@shared/services/utility.service";

export interface IMenuItem {
    name: string; // 'Home'
    route: string; // '/home'
    routeParams?: Params; // query params
    fragment?: string; // Id of the DOM element to scroll to
    /**
     * matchStrategy:
     * "full" - pathname must exactly match the value of 'route' (default)
     * "partial" - pathname should start with the 'route' value
     */
    matchStrategy?: "full" | "partial";
    click?: string;
    icon: string; // 'fas fa-home'
    permission?: number; // 2 // permissions.HomePage
    permissionActions?: number[];
    hasData?: string; // fn name
    restriction?: string;
    restrictionParameters?: any;
    isActive?: boolean;
}

export interface IMenuItemParent extends IMenuItem {
    children?: IMenuItem[];
    expanded?: boolean;
}

/** @returns copy of MENU_ITEMS */
export function getMenuItems() {
    return UtilityService.deepClone(MENU_ITEMS);
}

const MENU_ITEMS: IMenuItemParent[] = [
    {
        name: "Home",
        route: "/home",
        icon: "home_filled",
        permission: PERMISSIONS.HomePage,
        hasData: "ANY",
        children: [],
    },
    {
        name: "Live Status",
        route: "/live-status",
        icon: "diagnostics",
        permission: PERMISSIONS.LiveStatusPage,
        hasData: "ANY",
        children: [],
    },
    {
        name: "Dashboard",
        route: "/dashboard",
        icon: "speed",
        permission: PERMISSIONS.DashboardPage,
        hasData: "ANY",
        children: [],
    },
    {
        name: "Colocation",
        route: "/colocation",
        matchStrategy: "partial",
        click: "checkColocationPNCBank",
        icon: "outlet",
        permission: PERMISSIONS.ColocationPage,
        hasData: "COLOCATION",
        expanded: true,
        children: [
            {
                name: "Interconnects",
                route: "/colocation/interconnects",
                matchStrategy: "partial",
                icon: "fas fa-circle",
                permission: PERMISSIONS.ColocationInterconnectPage,
                hasData: "ANY",
            },
        ],
    },
    {
        name: "Network & Internet",
        route: "/network-data",
        icon: "lte",
        permission: PERMISSIONS.NetworkDataPage,
        hasData: "NETWORK",
        children: [],
    },
    {
        name: "Datacenter Physical Access",
        route: "/datacenter-access",
        click: "checkDCPANavigationTarget",
        icon: "door_open",
        permission: PERMISSIONS.DataCenterPhysicalAccessPage,
        hasData: "DATACENTER_PHYSICAL_ACCESS",
        expanded: true,
        children: [
            {
                name: "Activate New Access",
                route: "/datacenter-access/activate-access",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "ANY",
                restriction: "DCPA_FULL_ACCESS",
            },
            {
                name: "Deactivate Existing Access",
                route: "/datacenter-access/deactivate-access",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "ANY",
                restriction: "DCPA_FULL_ACCESS",
            },
            {
                name: "Reactivate Access",
                route: "/datacenter-access/cardholder-access-list/reactivate-access",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "DATACENTER_PHYSICAL_ACCESS_TABS",
                restriction: "DCPA_ACCESS_BY_PERMISSIONS",
                restrictionParameters: PERMISSIONS.DataCenterPhysicalAccessPageCardholderAccessList,
            },
            {
                name: "Cardholder Access List",
                route: "/datacenter-access/cardholder-access-list",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "DATACENTER_PHYSICAL_ACCESS_TABS",
                restriction: "DCPA_ACCESS_BY_PERMISSIONS",
                restrictionParameters: PERMISSIONS.DataCenterPhysicalAccessPageCardholderAccessList,
            },
            {
                name: "Datacenter Access Report",
                route: "/datacenter-access/datacenter-access-report",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "DATACENTER_PHYSICAL_ACCESS_TABS",
                restriction: "DCPA_ACCESS_BY_PERMISSIONS",
                restrictionParameters:
                    PERMISSIONS.DataCenterPhysicalAccessPageDatacenterAccessReport,
            },
        ],
    },
    {
        name: "Managed Systems",
        route: "/managed-systems",
        matchStrategy: "partial",
        click: "checkMSNavigationTarget",
        icon: "manage_systems",
        permission: -1,
        hasData: "MANAGED_SYSTEMS",
        restriction: "ANY_PERMISSION",
        restrictionParameters: [PERMISSIONS.SystemsPage, PERMISSIONS.LoadBalancerPage],
        expanded: true,
        children: [
            {
                name: "Virtual Private Cloud",
                route: "/managed-systems",
                fragment: "vpc",
                icon: "fas fa-circle",
                permission: PERMISSIONS.SystemsPageVirtualPrivateCloud,
                hasData: "MANAGED_SYSTEMS_VPC",
            },
            {
                name: "Licensing",
                route: "/managed-systems",
                fragment: "licensing",
                icon: "fas fa-circle",
                permission: PERMISSIONS.SystemsPageLicensing,
                hasData: "MANAGED_SYSTEMS_LICENSING",
            },
            {
                name: "Servers",
                route: "/managed-systems",
                routeParams: { type: "server" },
                fragment: "datacenters",
                icon: "fas fa-circle",
                permission: PERMISSIONS.SystemsPageServers,
                hasData: "MANAGED_SYSTEMS_SERVERS",
            },
            {
                name: "Firewall",
                route: "/managed-systems",
                routeParams: { type: "firewall" },
                fragment: "datacenters",
                icon: "fas fa-circle",
                permission: PERMISSIONS.SystemsPageFirewalls,
                hasData: "MANAGED_SYSTEMS_FIREWALL",
            },
            {
                name: "Disaster Recovery",
                route: "/managed-systems",
                routeParams: { type: "vpg" },
                fragment: "datacenters",
                icon: "fas fa-circle",
                permission: PERMISSIONS.SystemsPageDisasterRecovery,
                hasData: "MANAGED_SYSTEMS_VPG",
            },
            {
                name: "Load Balancer",
                route: "/managed-systems/load-balancer",
                icon: "fas fa-circle",
                permission: PERMISSIONS.LoadBalancerPage,
                hasData: "MANAGED_SYSTEMS_LOAD_BALANCER",
            },
            {
                name: "Storage",
                route: "/managed-systems",
                routeParams: { type: "storage" },
                fragment: "datacenters",
                icon: "fas fa-circle",
                permission: PERMISSIONS.SystemsPageStorage,
                hasData: "MANAGED_SYSTEMS_STORAGE",
            },
        ],
    },
    {
        name: "Managed Services",
        route: "/managed-services",
        icon: "support",
        permission: PERMISSIONS.ServicesPage,
        hasData: "ANY",
        expanded: true,
        children: [
            {
                name: "SSL",
                route: "/managed-services/ssl-certificates",
                icon: "fas fa-circle",
                permission: PERMISSIONS.ServicesPageSSLCertificates,
                hasData: "ANY",
            },
            {
                name: "CloudFlare",
                route: "/managed-services/cloudflare-analytics",
                icon: "fas fa-circle",
                permission: PERMISSIONS.ServicesPageCloudFlare,
                hasData: "MANAGED_SERVICES_CLOUDFLARE",
            },
        ],
    },
    {
        name: "Support",
        route: "/support/history",
        click: "checkTicketHistoryNavigation",
        icon: "group_chat",
        permission: PERMISSIONS.SupportPage,
        hasData: "ANY",
        restriction: "SUPPORT_PARENT_DISPLAY",
        expanded: true,
        children: [
            {
                name: "Release Notes",
                route: null,
                click: "navigateToReleaseNotes",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "ANY",
            },
            {
                name: "Video Tutorials",
                route: "/support/video-tutorials",
                icon: "fas fa-circle",
                permission: PERMISSIONS.VideoTutorial,
                hasData: "ANY",
            },
            {
                name: "Add New Ticket",
                route: "/support/new-ticket",
                icon: "fas fa-circle",
                permission: PERMISSIONS.AddNewTicket,
                permissionActions: [PERMISSIONS_ACTIONS.add],
                hasData: "ANY",
            },
            {
                name: "Reserve Conference Room",
                route: "/support/reserve-conference-room",
                icon: "fas fa-circle",
                permission: PERMISSIONS.ReserveConferenceRoomPage,
                permissionActions: [PERMISSIONS_ACTIONS.add],
                hasData: "ANY",
            },
            {
                name: "New Delivery Ticket",
                route: "/support/new-delivery-ticket",
                icon: "fas fa-circle",
                permission: PERMISSIONS.NewDeliveryTicketPage,
                permissionActions: [PERMISSIONS_ACTIONS.add],
                hasData: "ANY",
            },
            {
                name: "Request Remote Hands",
                route: "/support/request-remote-hands",
                icon: "fas fa-circle",
                permission: PERMISSIONS.RequestRemoteHandsPage,
                permissionActions: [PERMISSIONS_ACTIONS.add],
                hasData: "ANY",
            },
            {
                name: "Request Disconnect",
                route: "/support/request-disconnect-ticket",
                icon: "fas fa-circle",
                permission: PERMISSIONS.RequestDisconnect,
                permissionActions: [PERMISSIONS_ACTIONS.add],
                hasData: "ANY",
            },
            {
                name: "New Billing Inquiry",
                route: "/support/billing-inquiry",
                icon: "fas fa-circle",
                permission: PERMISSIONS.NewBillingInquiry,
                permissionActions: [PERMISSIONS_ACTIONS.add],
                hasData: "ANY",
            },
        ],
    },
    {
        name: "Compliance",
        route: "/compliance",
        icon: "clipboard_check",
        permission: PERMISSIONS.CompliancePage,
        hasData: "ANY",
        expanded: true,
        children: [
            {
                name: "Security Documents",
                route: null,
                click: "navigateToTrustPortal",
                icon: "fas fa-circle",
                permission: -1,
                hasData: "ANY",
            },
        ],
    },
    {
        name: "Security",
        route: "/security",
        routeParams: { filter: null },
        icon: "shield_protected",
        permission: PERMISSIONS.SecurityPage,
        hasData: "SECURITY",
        children: [],
    },
    {
        name: "Analytics",
        route: "/analytics",
        click: "navigateAnalytics",
        icon: "chart_pie",
        permission: PERMISSIONS.Analytics,
        hasData: "ANALYTICS",
        expanded: true,
        children: [
            {
                name: "Account Management",
                route: "/analytics/account-management",
                matchStrategy: "partial",
                icon: "fas fa-circle",
                permission: PERMISSIONS.AccountManagement,
                hasData: "ANY",
            },
            {
                name: "Monitoring Dashboard",
                route: null,
                click: "monitoringDashboard",
                icon: "fas fa-circle",
                permission: PERMISSIONS.MonitoringDashboard,
                hasData: "ANALYTICS_MONITORING_DASHBOARD",
            },
        ],
    },
    {
        name: "My Account",
        route: "/my-account/overview",
        click: "checkMyAccount",
        icon: "how_to_reg",
        permission: -1,
        hasData: "MY_ACCOUNT",
        restriction: "ANY_PERMISSION",
        restrictionParameters: [
            PERMISSIONS.MyAccountAccountOverviewPage,
            PERMISSIONS.MyAccountManageUsers,
            PERMISSIONS.MyAccountManageRoles,
            PERMISSIONS.MyAccountAuthorizedSigners,
            PERMISSIONS.MyAccountManageTags,
            PERMISSIONS.MyProfilePage,
        ],
        expanded: false,
        children: [
            {
                name: "Manage Users",
                route: "/my-account/manage-users",
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyAccountManageUsers,
                hasData: "ANY",
            },
            {
                name: "Manage Roles",
                route: "/my-account/manage-roles",
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyAccountManageRoles,
                hasData: "ANY",
            },
            {
                name: "Authorized Signers",
                route: "/my-account/authorized-signers",
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyAccountAuthorizedSigners,
                hasData: "ANY",
                restriction: "AUTHORIZED_SIGNERS_DISPLAY",
            },
            {
                name: "Manage Tags",
                route: "/my-account/manage-tags",
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyAccountManageTags,
                hasData: "ANY",
            },
            {
                name: "My Profile",
                route: "/my-profile",
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyProfilePage,
                hasData: "ANY",
            },
        ],
    },
];

export const METADATA = {
    PNCBankNationalAssociationOrgId: "88572ffe-6ee7-4b83-89d0-96fc84e21f42",
};
