import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const timezoneOffset = (new Date()).getTimezoneOffset() * -1 / 60;

    const modifiedRequest = request.clone({
      setHeaders: {
        timezoneOffset: timezoneOffset.toString(),
      },
    });

    return next.handle(modifiedRequest);
  }
}