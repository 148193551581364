import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { Injectable } from "@angular/core";
import { AuthStorageService } from "./storages/auth-storage.service";

@Injectable({
   providedIn: "root",
})

export class AuthorizedSignersStorageService {

    constructor(
        private userService: UserStorageService,
        private organizationService: OrganizationStorageService,
        private authStorageService: AuthStorageService,
    ) {}

    public checkAuthorizedSigners(): boolean {
        var result =
            ((!this.organizationService.activeGroup &&
                this.organizationService.activeOrganization.isAliased) 
                || !!this.organizationService.activeGroup 
                || this.authStorageService.isBackdoorUser());
        result = result ? result : false;
        return this.userService.getUser()?.user.isAdministrator && result;
    }
}