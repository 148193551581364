/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
} from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { forkJoin, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { PERMISSIONS, PERMISSIONS_ACTIONS } from "@core/constants/permission.constant";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { MonitoringEM7Service } from "@core/services/monitoring-em7.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { PermissionsStorageService } from "@core/services/storages/permissions-storage.service";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { OrganizationDataAvailabilityStorageService } from "@core/services/storages/organization-data-availability-storage.service";
import { DatacenterLocation } from "@shared/interfaces/datacenter-location.interface";
import { CommonLookupsApiService } from "@shared/services/common-lookups.service";
import { DCPhysicalAccessAPIService } from "@shared/services/dc-physical-access.api.service";
import { IMenuItem, IMenuItemParent, getMenuItems, METADATA } from "./sidebar.metadata";
import { GeneralSettingsService } from "@core/services/general-settings.service";
import { AuthorizedSignersStorageService } from "@core/services/authorized-signers-storage.service";

@Component({
    selector: "edge-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
    // menu
    public isLoading = true;
    public menu: IMenuItemParent[] = [];
    private menuAllowed: IMenuItemParent[] = [];

    // global sidebar state
    private isCollapsed = false;
    private isHovered = false;
    private isFlagsReady = false;
    private isPAAccessIntegrationComplete = false;

    private destroy$: Subject<void> = new Subject<void>();

    // special parameters
    private dcLocation: DatacenterLocation[] = [];
    private dcFullAccess: boolean = false;
    private urlPNCBank: string | null = null;
    private releaseNotesUrl: string;
    private trustUrl: string;

    // special clicks processing
    private clicks = {
        navigateToReleaseNotes: () => window.open(this.releaseNotesUrl, "_blank"),
        navigateToTrustPortal: () => window.open(this.trustUrl, "_blank"),
        monitoringDashboard: () =>
            window.open(this.monitoringEM7.dashboardPage, "window", "width=700,height=700"),
        checkColocationPNCBank: () => {
            // if current ORG = PNCBank -> go to external page
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.urlPNCBank ? window.open(this.urlPNCBank) : this.router.navigate(["/colocation"]);
        },
        checkMSNavigationTarget: index => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.menu[index].children.some(item => item.route === "/managed-systems") &&
                this.router.navigate(["/managed-systems"]);
        },
        checkDCPANavigationTarget: () => {
            this.router.navigate([
                this.restrictions.DCPA_ACCESS_BY_PERMISSIONS(
                    PERMISSIONS.DataCenterPhysicalAccessPageCardholderAccessList
                ) && this.dataAvailabilityService.flags.DATACENTER_PHYSICAL_ACCESS_TABS
                    ? "/datacenter-access"
                    : "/datacenter-access/datacenter-access-report",
            ]);
        },
        navigateAnalytics: index => {
            // make the link clickable only in case any child has route to account management
            if (
                this.menu[index].children.some(
                    item => item.route === "/analytics/account-management"
                )
            ) {
                this.router.navigate(["/analytics/account-management"]);
            }
        },
        checkMyAccount: () => {
            // make the link clickable only in case it has a permission to access My Account Overview page
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.permissionsService.has(PERMISSIONS.MyAccountAccountOverviewPage) &&
                this.router.navigate(["/my-account"]);
        },
        checkTicketHistoryNavigation: () => {
            // make the link clickable only in case it has a permission to access Ticket History page
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.permissionsService.has(PERMISSIONS.SupportPageTicketHistory) &&
                this.router.navigate(["/support/history"]);
        },
    };

    // additional restrictions if needed
    private restrictions = {
        NO: () => true,
        DCPA_FULL_ACCESS: () => this.dcFullAccess,
        DCPA_ACCESS_BY_PERMISSIONS: (permission: number) => {
            return (
                !!this.dcLocation?.length &&
                (this.authService.isBackdoorUser ||
                    !!this.userService.getUser()?.user.physicalAccessAdministrator ||
                    this.permissionsService.hasView(permission))
            );
        },
        ANY_PERMISSION: (permissions: number[] = []) => this.permissionsService.hasAny(permissions),
        SUPPORT_PARENT_DISPLAY: (index: number) => {
            return (
                this.permissionsService.has(PERMISSIONS.SupportPageTicketHistory) ||
                !!this.menu[index]?.children.length
            );
        },
        AUTHORIZED_SIGNERS_DISPLAY: () => {
            return this.authorizedSignersStorageService.checkAuthorizedSigners();
        },
    };

    constructor(
        private router: Router,
        private authService: AuthStorageService,
        private userService: UserStorageService,
        private lookupsService: CommonLookupsApiService,
        private settingsService: SettingStorageService,
        private organizationService: OrganizationStorageService,
        private permissionsService: PermissionsStorageService,
        private dataAvailabilityService: OrganizationDataAvailabilityStorageService,
        private monitoringEM7: MonitoringEM7Service,
        private dcpaService: DCPhysicalAccessAPIService,
        private readonly cdr: ChangeDetectorRef,
        private authorizedSignersStorageService: AuthorizedSignersStorageService,
        private generalSettingsService: GeneralSettingsService
    ) {
        this.generalSettingsService.settings$.subscribe(data => {
            this.releaseNotesUrl = data?.releaseNotesUrl;
            this.trustUrl = data?.trustUrl;
        });
    }

    public ngOnInit(): void {
        /**
         * Router events processing
         */
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                // find and activate related item(s)
                this.activateMenuItem(event.urlAfterRedirects);

                // Emit this change
                this.cdr.detectChanges();
            }
        });

        /**
         * Track expand | collapse state globally
         */
        this.settingsService
            .isSidebarCollapsed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(isCollapsed => {
                this.isCollapsed = isCollapsed;
                // off hovered flag
                if (isCollapsed) {
                    this.isHovered = false;
                }
            });

        /**
         * Get DC access parameters
         */
        this.dcpaService
            .getPhysicalAccessIntegrationComplete()
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    // Apply checking of data availability
                    this.isPAAccessIntegrationComplete = true;
                    this.isFlagsReady && this.applyFlags();

                    this.isLoading = false;
                    this.cdr.detectChanges();
                })
            )
            .subscribe((data = []) => {
                this.dcLocation = data;
                this.dcFullAccess =
                    !!data.length &&
                    (this.authService.isBackdoorUser() ||
                        !!this.userService.getUser()?.user.physicalAccessAdministrator);
                this.applyAdditionalRestrictions("DCPA_FULL_ACCESS");
                this.applyAdditionalRestrictions("DCPA_ACCESS_BY_PERMISSIONS");
            });

        /**
         * take and update state of dependent flags
         */
        forkJoin({
            portAssets: this.lookupsService.getBillablePortsAssets(),
            zColoPorts: this.lookupsService.getZColoPorts(),
        })
            .pipe(takeUntil(this.destroy$))
            .subscribe(obj => {
                // update NETWORK data availability flag if there are assets or ports available
                this.dataAvailabilityService.recalculateFlag(
                    "NETWORK",
                    !!obj.portAssets?.length || !!obj.zColoPorts?.length
                );
            });

        // TODO: use combineLatest
        // Process permissions
        this.organizationService.permissions$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            if (value) {
                // run prechecks
                this.runPrechecks();
                // Apply mapped permissions to generate menuAllowed
                this.applyPermissions();
                // Apply additional rule for Managed Systems
                this.applyAdditionalRestrictions("ANY_PERMISSION");
                this.applyAdditionalRestrictions("IS_ADMIN");
                this.applyAdditionalRestrictions("AUTHORIZED_SIGNERS_DISPLAY");
            } else {
                this.menu = [];
            }
        });

        // Process data availability flags
        this.organizationService.flags$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            if (value) {
                // Apply data flags to generate menuAvailable based on menuAllowed
                // but only in case permissions check was done
                this.isFlagsReady = true;
                this.isPAAccessIntegrationComplete && this.applyFlags();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * EXPAND | COLLAPSE | HOVER panel
     */
    get statusClass(): string {
        return `${this.isCollapsed ? "collapsed" : ""} ${this.isHovered ? "hovered" : ""}`;
    }

    public toggleStatus(): void {
        this.settingsService.updateSetting({
            sideBarCollapsed: !this.isCollapsed,
        });
        // Needed for ApexCharts so it can rerender all charts and adjust the width
        window.dispatchEvent(new Event("resize"));
    }

    public hover(): void {
        this.isHovered = true;
    }

    public unhover(): void {
        this.isHovered = false;
    }

    /**
     * EXPAND | COLLAPSE menu item
     */
    public toggle(index: number): void {
        this.menu[index].expanded = !this.menu[index].expanded;
    }

    /**
     * processing of CLICK on menu options
     */
    public onClick(parentIndex: number, childIndex: number = -1): void {
        const node =
            childIndex !== -1
                ? this.menu[parentIndex]?.children[childIndex]
                : this.menu[parentIndex];
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        typeof this.clicks[node?.click] === "function"
            ? this.clicks[node.click](parentIndex)
            : node.route &&
              this.router.navigate([node.route], {
                  queryParams: node.routeParams,
                  fragment: node.fragment,
              });
    }

    /**
     * STEP #0: MENU GENERATION -> PRECHECKS
     */
    private runPrechecks(): void {
        // precheck PNCBank organization -> get URL as exception for Colocation link redirection
        this.urlPNCBank = null;
        this.settingsService
            .getPNCBankURL()
            .pipe(takeUntil(this.destroy$))
            .subscribe(url => {
                this.urlPNCBank =
                    url &&
                    this.organizationService.activeOrganization?.id ===
                        METADATA.PNCBankNationalAssociationOrgId
                        ? url
                        : null;
            });
    }

    /**
     * STEP #1: MENU GENERATION -> PERMISSIONS
     */
    private applyPermissions(): void {
        // process parent items
        this.menuAllowed = getMenuItems().filter(item => {
            return (
                item.permission === -1 ||
                (item.permissionActions || [PERMISSIONS_ACTIONS.view]).every(action =>
                    this.permissionsService.has(item.permission, action)
                )
            );
        });
        // process children
        this.menuAllowed.forEach(parent => {
            if (parent.children.length) {
                parent.children = parent.children.filter(item => {
                    return (
                        item.permission === -1 ||
                        (item.permissionActions || [PERMISSIONS_ACTIONS.view]).every(action =>
                            this.permissionsService.has(item.permission, action)
                        )
                    );
                });
            }
        });
    }

    /**
     * STEP #2: MENU GENERATION -> APPLY ADDITIONAL RESTRICTION
     */
    private applyAdditionalRestrictions(restrictionCode: string = "NO") {
        // Special rules
        this.menuAllowed = this.menuAllowed.filter(parent => {
            // Apply restrictions for children first
            if (parent.children.length) {
                parent.children = parent.children.filter(child => {
                    return child.restriction === restrictionCode
                        ? this.restrictions[restrictionCode](child.restrictionParameters)
                        : true;
                });
            }
            // parent restriction
            return parent.restriction === restrictionCode
                ? this.restrictions[restrictionCode](parent.restrictionParameters)
                : true;
        });
    }

    /**
     * STEP #3: MENU GENERATION -> DATA AVAILABILITY FLAGS
     */
    private applyFlags(): void {
        // Process parent items
        const menuAvailable: IMenuItemParent[] = this.menuAllowed.filter(
            item => !!this.dataAvailabilityService.flags[item.hasData]
        );
        // Process children
        menuAvailable.forEach(parent => {
            if (parent.children.length) {
                parent.children = parent.children.filter(
                    item => !!this.dataAvailabilityService.flags[item.hasData]
                );
            }
        });
        // Apply additional restrictions if needed
        this.applyChildrenPermissions4Parents(menuAvailable);

        // find and activate necessary sidebar item first time
        this.activateMenuItem(this.router.url);

        this.cdr.detectChanges();
    }

    /**
     * STEP #4: MENU GENERATION -> APPLY CHILDREN PERMISSIONS 4 PARENTS
     */
    private applyChildrenPermissions4Parents(menuAvailable: IMenuItemParent[] = []) {
        // Parent node has no route link so it has no sense to display it with no children
        const noParentsWithoutChildren = [
            "Managed Systems",
            "Managed Services",
            "Datacenter Physical Access",
            "Analytics",
        ];
        this.menu = menuAvailable.filter(item => {
            return !(noParentsWithoutChildren.includes(item.name) && !item.children.length);
        });
        // Apply specific restriction for Support parent menu item
        const supportItemIndex: number = this.menu.findIndex(
            item => item.restriction === "SUPPORT_PARENT_DISPLAY"
        );
        if (supportItemIndex > -1 && !this.restrictions.SUPPORT_PARENT_DISPLAY(supportItemIndex)) {
            this.menu.splice(supportItemIndex, 1);
        }
    }

    /**
     * HELPER: find menu item by route name
     */
    private activateMenuItem(route: string): void {
        this.menu.forEach(parent => {
            parent.isActive = this.isRouteActive(parent, route);

            parent.children.forEach(child => {
                child.isActive = this.isRouteActive(child, route);

                if (child.isActive) parent.isActive = true;
            });
        });
    }

    private isRouteActive(routeConfig: IMenuItem, activatedRoute: string): boolean {
        // Origin of the URL doesn't matter, but it is required for creating a new URL instance
        const activatedUrl = new URL(activatedRoute, "http://any.any");

        const isSamePath =
            routeConfig.route === activatedUrl.pathname ||
            (routeConfig.matchStrategy === "partial" &&
                activatedUrl.pathname.startsWith(routeConfig.route));

        // It doesn't support arrays in query params (?type=server&type=firewall).
        // Feel free to add that functionality if needed
        const isSameParams =
            !routeConfig.routeParams ||
            Object.entries(routeConfig.routeParams).every(
                ([key, value]) => activatedUrl.searchParams.get(key) === value
            );

        const isSameHash =
            !routeConfig.fragment || `#${routeConfig.fragment}` === activatedUrl.hash;

        return isSamePath && isSameParams && isSameHash;
    }
}
