import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { webApiUrls } from "@core/constants/url.constant";
import { ITokenDto } from "@core/interfaces/dto/token-dto.interface";
import { IUserProfileDto } from "@core/interfaces/dto/user-profile-dto.interface";
import { AuthDuoService } from "@core/services/auth-duo.service";
import { GlobalCacheService } from "@core/services/global-cache.service";
import { HttpClient } from "@angular/common/http";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { PermissionsStorageService } from "@core/services/storages/permissions-storage.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { Observable, of } from "rxjs";
import { catchError, finalize, map, switchMap, take, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { UserApiService } from "./user-api.service";
import { componentWithUnsavedChangesExist } from "@core/decorators/unsaved-changes.decorator";
import { DialogService } from "@shared/components/dialog/dialog.service";
import { IUserParams } from "@core/interfaces/dto/user-profile-dto.interface";

@Injectable({
    providedIn: "root",
})
export class AuthApiService {

    public get ignoreUnsavedChangesGuard() {
        return this._ignoreUnsavedChangesGuard;
    }

    private _ignoreUnsavedChangesGuard = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authStorageService: AuthStorageService,
        private userStorageService: UserStorageService,
        private userApiService: UserApiService,
        private settingStorageService: SettingStorageService,
        private organizationStorageService: OrganizationStorageService,
        private permissionsStorageService: PermissionsStorageService,
        private duoUserService: AuthDuoService,
        private ref: MatDialog,
        private dialogService: DialogService
    ) {}

    /**
     * Simple auth with login and password
     */
    public simpleAuth(userName: string, password: string): Observable<IUserProfileDto | boolean> {
        const body = new URLSearchParams();
        body.set("grant_type", "password");
        body.set("username", userName);
        body.set("password", password);

        return this.getToken(body).pipe(
            switchMap(() => {
                // Check isPasswordExpired and navigate
                if (this.authStorageService.getAuth().isPasswordExpired) {
                    this.router.navigate(["/reset-password"]);
                    return of(false);
                }

                // Check isDuoUser and navigate
                if (this.authStorageService.isDuoUser()) {
                    return this.userHasActivatedPhoneCheck(userName);
                }

                return this.userApiService.getAndSaveUserProfile$;
            }),
            tap((data: IUserProfileDto | boolean) => {
                if (data) {
                    this.redirectToPortal();
                }
            })
        );
    }

    /**
     * Auth with Epicor (backdoor)
     */
    public epicoreAuth(sessionId: string, optionalId?: string): Observable<ITokenDto> {
        // Query param
        let params = new HttpParams();
        params = params.set("ee10910", sessionId);
        if (optionalId) {
            params = params.set("O", optionalId);
        }

        // Post param
        const body = new URLSearchParams();
        body.set("grant_type", "client_credentials");

        return this.getToken(body, params).pipe(
            tap(() => {
                this.authStorageService.updateAuthData({
                    isBackdoorUser: true,
                });
            }),
            catchError(error => {
                this.router.navigate(["/login"]);
                return of(error);
            })
        );
    }

    /**
     * Auth with Duo PUSH data (backdoor)
     */
    public authWithPasscode(passcode: number): Observable<boolean> {
        const headers = { "Content-Type": "application/json" };

        return this.http
            .post<any>(`${webApiUrls.auth}/DuoAuth/AuthWithPasscode`, JSON.stringify(passcode), { headers })
            .pipe(
                map(res => {
                    const auth = res.response?.status === "allow";
                    if (auth) {
                        this.authStorageService.authorizeDuo();
                    }
                    return auth;
                }),
                catchError(() => of(false))
            );
    }

    /**
     * Auth with Duo PUSH data (backdoor)
     */
    public authWithPush(deviceId: string): Observable<boolean> {
        const headers = { "Content-Type": "application/json" };
        return this.http.post<any>(`${webApiUrls.auth}/DuoAuth/Push`, JSON.stringify(deviceId), { headers }).pipe(
            map(res => {
                const auth = res.response?.status === "allow";
                if (auth) {
                    this.authStorageService.authorizeDuo();
                }
                return auth;
            }),
            catchError(() => of(false))
        );
    }

    /**
     * HTTP GET get Token
     */
    public getToken(body: Params, params?: Params): Observable<ITokenDto> {
        const headers = new HttpHeaders()
            .set("Content-Type", "application/x-www-form-urlencoded")
            .set("skip-token", "true");

        return this.http
            .post<ITokenDto>(`${webApiUrls.authBase}/token`, body.toString(), {
                headers,
                params: params || null,
                observe: "response",
            })
            .pipe(
                map(data => {
                    const userSessionData = JSON.parse(data.headers.get("UserSessionData"));

                    // Todo: Mapping
                    this.authStorageService.updateAuthData({
                        ...data.body,
                        duoEnabled: JSON.parse(userSessionData.DuoEnabled.toLowerCase()),
                        duoAuthorized: false,
                        isPasswordExpired: JSON.parse(
                            userSessionData.IsPasswordExpired.toLowerCase()
                        ),
                        sessionId: userSessionData.SessionId,
                        isBackdoorUser: false,
                        showAnnouncements: true,
                        showNotices: true,
                    });
                    this.settingStorageService.initUserSetting(body.get("username"));

                    return data.body;
                }),
                /**
                 * Get redirection URL for Colocation | exception
                 */
                tap(() => {
                    this.http
                        .get(`${webApiUrls.productManagement}/Colocation/ColocationDcimUrl`)
                        .subscribe((url: string | undefined) =>
                            this.settingStorageService.updateSetting({
                                urlPNCBank: url || null,
                            })
                        );
                })
                // catchError( () => {})
            );
    }

    public safeLogout(): Observable<void> {
        if (componentWithUnsavedChangesExist()) {
            return this.dialogService.unsavedChanges().pipe(
                switchMap(shouldLogOut => {
                    if (shouldLogOut) {
                        return this.logoutRequest();
                    }
                    return of();
                })
            );
        }

        return this.logoutRequest();
    }

    public forceLogout(): Observable<void> {
        return this.logoutRequest();
    }

    public clearStoragesAndNavigate(): void {
        GlobalCacheService.clearAll();
        this.authStorageService.clearAuthData();
        this.userStorageService.clearUserData();
        this.settingStorageService.clearContext();
        this.organizationStorageService.setData([]);
        this.permissionsStorageService.setData([]);
        this._ignoreUnsavedChangesGuard = true;
        this.router.navigate(["/login"]).then(() => this._ignoreUnsavedChangesGuard = false);
    }

    public registerUser(email: string): Observable<any> {
        const headers = new HttpHeaders().set("skip-token", "true");
        return this.http.post(
            `${webApiUrls.accountManagement}/Users/SelfRegistrationEmail`,
            { email },
            { headers }
        );
    }

    public retrieveUsername(email: string): Observable<any> {
        const headers = new HttpHeaders().set("skip-token", "true");
        return this.http.post(
            `${webApiUrls.accountManagement}/RetrieveUsername`,
            { email },
            { headers }
        );
    }

    public sendForgotPasswordEmail(username: string): Observable<any> {
        const headers = new HttpHeaders().set("skip-token", "true");
        return this.http.post(
            `${webApiUrls.accountManagement}/Users/ForgotPasswordEmail`,
            { username },
            { headers }
        );
    }

    public redirectToPortal(): void {
        if (this.settingStorageService.hasRedirectUrl()) {
            const url = this.settingStorageService.getRedirectUrl();
            this.router.navigate([url.path], {
                state: url.params,
                queryParams: url.queryParams,
            });
            this.settingStorageService.cleanRedirectUrl();
        } else {
            this.router.navigate(["/home"]);
        }
    }

    public getCurrentProfile(): Observable<IUserProfileDto> {
        const params = new URLSearchParams(window.location.search);

        // when we try to go to the portal from Epicor we have this values in queryParams
        const optionalId = params.get("O");
        const sessionId = params.get("ee10910");

        const isBackdoorUser = !!sessionId;

        return isBackdoorUser ? this.getBackdoorProfile(sessionId, optionalId) : this.getProfile();
    }

    private logoutRequest(): Observable<void> {
        return this.http.post<void>(`${webApiUrls.auth}/Auth/Logout`, null).pipe(
            finalize(() => {
                this.ref.closeAll();
                this.clearStoragesAndNavigate();
            })
        );
    }

    private getBackdoorProfile(sessionId: string, optionalId: string): Observable<IUserProfileDto> {
        return this.epicoreAuth(sessionId, optionalId).pipe(
            switchMap(() => {
                const params: IUserParams = {}

                if (optionalId) {
                    params.organizationId = optionalId;
                } else {
                    const activeOrganizationId = this.settingStorageService.getActiveOrganizationId();
                    if (activeOrganizationId) {
                        params.organizationId = activeOrganizationId;
                    }
                }

                const activeGroupId = this.settingStorageService.getActiveGroupId();
                const activeUserId = this.settingStorageService.getActiveUserId();
    
                if (activeGroupId) {
                    params.groupId = activeGroupId;
                }
    
                if (activeUserId) {
                    params.userId = activeUserId;
                }
    
                return this.userApiService.getAndSaveUserProfile(Object.keys(params).length ? params : undefined).pipe(take(1));
            })
        );
    }

    private getProfile(): Observable<IUserProfileDto> {
        const params: IUserParams = {}
        const activeOrganizationId = this.settingStorageService.getActiveOrganizationId();
        const activeGroupId = this.settingStorageService.getActiveGroupId();
        const activeUserId = this.settingStorageService.getActiveUserId();
    
        if (activeOrganizationId) {
            params.organizationId = activeOrganizationId;
        }
    
        if (activeGroupId) {
            params.groupId = activeGroupId;
        }
    
        if (activeUserId) {
            params.userId = activeUserId;
        }
    
        return this.authStorageService.isAuthorized()
            ? this.userApiService.getAndSaveUserProfile(Object.keys(params).length ? params : undefined)
            : of(null);
    }

    private userHasActivatedPhoneCheck(userName: string): Observable<boolean> {
        return this.duoUserService.userHasActivatedPhoneCheck(userName).pipe(
            map(isUserHasActivatedPhone => {
                // Redirect if user has TwoFactor authorization
                this.router.navigate(isUserHasActivatedPhone ? ["/twofactorlogin"] : ["/wizard"]);
                return false;
            })
        );
    }
}
